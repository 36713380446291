
import { LAYOUT_SET_TOP_HEADER } from '~/store/mutation-types';
import { pushTopHeader } from '~/plugins/gtm';

export default {
  computed: {
    topHeader() {
      return this.$store.state.layout.topHeader;
    },
    isHidden() {
      return this.topHeader?.isHidden || false;
    },
  },
  mounted() {
    if (this.topHeader) {
      pushTopHeader(this, 'view');
      this.$store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
        ...this.topHeader,
        height: this.$refs.topHeader?.$el.clientHeight || 0,
      });
    }
  },
  methods: {
    handleClose() {
      this.$store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
        ...this.topHeader,
        isHidden: true,
        height: 0,
      });
      pushTopHeader(this, 'close');
    },
    handleClick() {
      pushTopHeader(this, 'link');
    },
  },
};


import mergeI18n from '~/utils/merge-i18n';
import dataI18n from '~/i18n/messages/fr-fr.search.json';

export default {
  props: {
    displayBtnLabels: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      suggestions: [],
      labels: {
        inputId: 'search-faq',
        inputLabel: this.$i18n.t('search.input-label'),
        openLabel: this.$i18n.t('search.open-label'),
        closeLabel: this.$i18n.t('search.close-label'),
        searchLabel: this.$i18n.t('search.search-label'),
        clearLabel: this.$i18n.t('search.clear-label'),
        placeholder: this.$i18n.t('search.placeholder'),
        openBtnLabel: this.$i18n.t('search.open-btn-label'),
        closeBtnLabel: this.$i18n.t('search.close-btn-label'),
      },
    };
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    isPro() {
      return this.$store.state.layout.isPro;
    },
    isSearchPage() {
      return this.$route.path === '/recherche';
    },
    indices() {
      return this.$store.state.search.indices;
    },
    currentRefinement() {
      return this.$store.state.search.currentRefinement;
    },
    refine() {
      return this.$store.state.search.refine;
    },
    findContactPush() {
      const header = this.layout?.header;
      let data = null;

      if (this.isPro && header?.pro?.findContact) {
        data = header.pro.findContact;
      } else if (header.private?.findContact) {
        data = header.private.findContact;
      }

      return data;
    },
  },
  watch: {
    indices() {
      this.indicesToSuggestions();
    },
  },
  beforeCreate() {
    mergeI18n(this.$i18n, dataI18n);
  },
  methods: {
    goToPreviousRoute() {
      if (this.$route.path === '/recherche') {
        this.$nuxt.context.from
          ? this.$router.back()
          : this.$router.push({
              path: '/',
            });
      }
    },
    indicesToSuggestions() {
      const index = this.indices[0];

      if (!index) {
        return [];
      }

      const formatHit = (query, hit) => {
        if (query.length === 0 || query === hit) {
          return { value: hit, label: hit };
        } else {
          const lowercaseQuery = query.toLowerCase();
          const queryIndex = hit.startsWith(lowercaseQuery)
            ? hit.indexOf(lowercaseQuery)
            : -1;
          const queryLength = query.length;
          return {
            value: hit,
            label:
              queryIndex === -1
                ? `<mark>${hit}</mark>`
                : `${hit.substring(0, queryLength)}<mark>${hit.substring(
                    queryIndex + queryLength,
                    hit.length
                  )}</mark>`,
          };
        }
      };

      const userQuery = index.results.query;
      this.suggestions =
        index.hits.map((hit) => formatHit(userQuery, hit.query)) || [];
    },
  },
};
